<template>
  <base-section id="k-d-a-tech-detail-description" space="0">
    <v-container>
      <v-row no-gutters>
        <v-col>
          <div class="center mt-4 mt-md-4 row-wrapper cursor-pointer" @click="navBack">
            <v-btn icon elevation="1" class="main-bw-300 mr-3">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
            <div
              :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-semibold`"
              style="align-self: center"
            >
              {{ m_strBack }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          cols="12"
          :lg="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
          :md="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center">
            <img
              :src="currentTechnology.imgTechnology"
              :class="g_bLowestBr || g_bBaseBr ? 'top' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          cols="12"
          :lg="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
          :md="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
          :class="`px-${g_bLowerBr || g_bBaseBr ? 4 : 0}`"
          style="
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div>
            <div :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold`">
              {{ currentTechnology.strTechnology }}
            </div>
            <component
              :is="`p`"
              :class="`kda-ts-${g_bLowerBr ? 12 : 18}pt nunito wt-regular mt-4 mt-md-4`"
              v-html="currentTechnology.htmlDescription"
            />
          </div>
        </v-col>
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDATechDetailDescription',

  mixins: [BaseBreakpoint],

  computed: {
    m_strBack() {
      return this.$vuetify.lang.t('$vuetify.kda.technology.back');
    },
    arrTechnology() {
      return [
        {
          strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.title'),
          htmlDescription: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.long-desc'),
          imgTechnology: require('@/assets/photos/tech-list-1.png'),
          strLinkDetail: 'unreal-engine'
        },
        {
          strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.title'),
          htmlDescription: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.long-desc'),
          imgTechnology: require('@/assets/photos/tech-list-2.png'),
          strLinkDetail: 'my-sql'
        },
        {
          strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.title'),
          htmlDescription: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.long-desc'),
          imgTechnology: require('@/assets/photos/tech-list-3.png'),
          strLinkDetail: 'django-python'
        },
        {
          strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.unity.title'),
          htmlDescription: this.$vuetify.lang.t('$vuetify.kda.technology.unity.long-desc'),
          imgTechnology: require('@/assets/photos/tech-list-4.png'),
          strLinkDetail: 'unity'
        },
        {
          strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.title'),
          htmlDescription: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.long-desc'),
          imgTechnology: require('@/assets/photos/tech-list-6.png'),
          strLinkDetail: 'keda-engine'
        }
      ];
    },
    currentTechnology() {
      const currentTechnology = this.arrTechnology.filter(
        (item) => this.$route.params.strTechnology === item.strLinkDetail
      )[0];

      return currentTechnology;
    }
  },

  methods: {
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.img-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 290px;
      position: relative;
      margin-top: 50px;
    }
  }
}
</style>
